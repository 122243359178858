<template>
<div class="grid justify-items-center">
  <img 
    src="../assets/images/cofounders/text-image.jpg"
    class="rounded-lg shadow-xl mt-6 sm:mt-8 h-auto w-72 sm:w-80"
  />
 <div class="max-w-screen text-white p-4 sm:p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-20">
    <!--Card 1-->
    <div class="" style="width: 350px">
      <img
        src="../assets/images/cofounders/charbel.jpg"
        class="rounded-lg shadow-xl mb-4"
        alt=""
      />

      <div class="text-center">
        <h5 class="text-xl mb-1">Charbel Madi</h5>
        <p class="text-xs mb-5">INTERIOR ARCHITECT</p>
        <p class="mb-4 text-justify">
          An interior architect specialized in furniture design and execution. 
          Owner and manager of furniture design atelier in Beirut that has been catering to clients worldwide since 1973.
          From functionality to design, Charbel will create and implement the perfect interior space that mixes classic design with modern architecture.
        </p>
      </div>
    </div>

    <!-- Card 2 -->
    <div class="" style="width: 350px">
      <img
        src="../assets/images/cofounders/maroun.jpg"
        class="rounded-lg shadow-xl mb-4"
        alt=""
      />

      <div class="text-center">
        <h5 class="text-xl mb-1">Maroun Samia</h5>
        <p class="text-xs mb-5">MANAGING PARTNER</p>
        <p class="mb-4 text-justify">
          With an extensive experience in project management, acount management and a keen eye on small details, Maroun puts the customer at the center of each project to fully understand their needs and provide the best service. 
          His attention to details enables the execution of all designs and projects while respecting time requirements and budgets.
        </p>
      </div>
    </div>
  </div>
</div>
</template>
